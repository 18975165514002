<template>
  <main class="main article-page">
    <article class="container">
      <!--eslint-disable-->
      <h1>Политика обработки персональных данных</h1>
      <ol>
        <li><span class="marker">1.</span> Общие положения
          <ol>
            <li><span class="marker">1.1.</span> Политика обработки персональных данных (далее — «Политика») издана и
              применяется Индивидуальным предпринимателем Борлаковой Региной
              Руслановной (далее — «Оператор») в соответствии с п. 2 ч. 1 ст. 18.1 Федерального
              закона от 27.07.2006 N 152-ФЗ «О персональных данных».
              Настоящая Политика определяет порядок и условия Оператора в отношении
              обработки персональных данных.
              Все вопросы, связанные с обработкой персональных данных, не урегулированные
              настоящей Политикой, разрешаются в соответствии с действующим
              законодательством Российской Федерации в области персональных данных.
            </li>
            <li><span class="marker">1.2.</span> Целью обработки персональных данных является:
              <ul>
                <li><span class="marker">•</span> предоставление субъектам персональных данных услуг Оператора на
                  платформе «Etikaego» (далее — «Платформа»), которая принадлежит
                  Оператору на законных основаниях. На Платформе может
                  зарегистрироваться любой пользователь для цели последующего
                  предоставления таким пользователем при помощи платформы тренингов,
                  семинаров, курсов, очных и онлайн занятий в сети Интернет.
                </li>
                <li><span class="marker">•</span> обеспечение защиты прав и свобод человека и гражданина при обработке
                  его персональных данных, в том числе защиты прав на неприкосновенность
                  частной жизни, личную и семейную тайну;
                </li>
              </ul>
            </li>
            <li><span class="marker">1.3.</span> Обработка организована Оператором на принципах:
              <ul>
                <li><span class="marker">•</span> законности целей и способов обработки персональных данных,
                  добросовестности и справедливости в деятельности Оператора;
                </li>
                <li><span class="marker">•</span> достоверности персональных данных, их достаточности для целей обработки,
                  недопустимости обработки персональных данных, избыточных по отношению
                  к целям, заявленным при сборе персональных данных;
                </li>
                <li><span class="marker">•</span> обработки только персональных данных, которые отвечают целям их
                  обработки;
                </li>
                <li><span class="marker">•</span> соответствия содержания и объема обрабатываемых персональных данных
                  заявленным целям обработки. Обрабатываемые персональные данные не
                  должны быть избыточными по отношению к заявленным целям их обработки;
                </li>
                <li><span class="marker">•</span> недопустимости объединения баз данных, содержащих персональные
                  данные, обработка которых осуществляется в целях, не совместимых между
                  собой;
                </li>
                <li><span class="marker">•</span> обеспечения точности персональных данных, их достаточности, а в
                  необходимых случаях и актуальности по отношению к целям обработки
                  персональных данных. Оператор принимает необходимые меры либо
                  обеспечивает их принятие по удалению или уточнению неполных или
                  неточных данных;
                </li>
                <li><span class="marker">•</span> хранения персональных данных в форме, позволяющей определить субъекта
                  персональных данных, не дольше, чем этого требуют цели обработки
                  персональных данных.
                </li>
              </ul>
            </li>
            <li><span class="marker">1.4.</span> Персональные данные обрабатываются с использованием средств
              автоматизации.
            </li>
            <li><span class="marker">1.5.</span> Оператор обрабатывает следующие персональные данные:
              <ul>
                <li><span class="marker">•</span> фамилия, имя, отчество</li>
                <li><span class="marker">•</span> мобильный телефон</li>
                <li><span class="marker">•</span> адрес электронной почты</li>
              </ul>
              <ol>
                <li><span class="marker">1.5.1.</span> При этом некоторые обязательные и/или добровольные персональные
                  данные, по личному волеизъявлению пользователя Платформы, делаются таким
                  пользователем общедоступными для любых третьих лиц в порядке п.п.10, п.1
                  статьи 6 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».
                </li>
              </ol>
            </li>
            <li><span class="marker">1.6.</span> При обработке персональных данных Оператор применяет правовые,
              организационные и технические меры по обеспечению безопасности персональных
              данных в соответствии со ст. 19 Федерального закона от 27.07.2006 N 152-ФЗ «О
              персональных данных», которые закреплены в Положении «Об обеспечении
              безопасности персональных данных пользователей Платформы.
            </li>
            <li><span class="marker">1.7.</span> Оператор не раскрывает третьим лицам и не распространяет персональные
              данные без согласия субъекта персональных данных, кроме случаев, если это
              предусмотрено действующим законодательством РФ и настоящей Политикой.
            </li>
            <li><span class="marker">1.8.</span> Оценка вреда, который может быть причинен субъектам персональных данных
              в случае нарушения Оператором требований Федерального закона от 27.07.2006 N
              152-ФЗ «О персональных данных», определяется в соответствии со ст. ст. 15, 151,
              152, 1101 Гражданского кодекса Российской Федерации. Соотношение указанного
              вреда и принимаемых Оператором мер, направленных на предупреждение,
              недопущение и/или устранение его последствий, установлено в Положении «Об
              обеспечении безопасности персональных данных пользователей Платформы».
            </li>
            <li><span class="marker">1.9.</span> Условия обработки персональных данных Оператором:
              1. персональные данные обрабатываются Оператором после заключения
              субъектом персональных данных лицензионного договора — оферты на
              предоставление такому субъекту права использования обучающей
              Платформы. Согласно п. 5 статьи 6 Федерального закона от 27.07.2006 года
              № 152-ФЗ «О персональных данных», согласие субъекта персональных
              денных не требуется, так как субъект заключает договор по собственной
              инициативе и является выгодоприобретателем по заключенному им договору.
              2. Оператор не передает персональные данные субъекта третьим лицам.
            </li>
            <li><span class="marker">1.10.</span> Хранение персональных данных субъектов, которые являются
              пользователями Платформы, осуществляется в форме, позволяющей определить
              субъекта персональных данных. Персональные данные подлежат уничтожению по
              достижении целей обработки. Удаление персональных данных осуществляется
              посредством удаления учетной записи на Платформе, после чего такая запись не
              подлежит восстановлению.
            </li>
            <li><span class="marker">1.11.</span> Взаимодействие с федеральными органами исполнительной власти по
              вопросам обработки и защиты персональных данных субъектов, персональные
              данные которых обрабатываются Оператором, осуществляется в рамках
              законодательства Российской Федерации.
            </li>
            <li><span class="marker">1.12.</span> Обработка персональных данных осуществляется автоматизированно с
              помощью средств вычислительной техники.
            </li>
          </ol>
        </li>
        <li><span class="marker">2.</span> Ответственный за обработку персональных данных
          <ol>
            <li><span class="marker">2.1.</span>При обработке персональных данных Оператор является ответственным за
              обработку персональных данных.
            </li>
            <li><span class="marker">2.2.</span> Ответственный за обработку персональных данных контролирует процесс
              обработки персональных данных и обеспечивает реализацию всех положений
              настоящей Политики, относящихся к его компетенции.
            </li>
            <li>
              <span class="marker">2.3.</span> Ответственный за обработку персональных данных:
              <ol>
                <li><span class="marker">2.3.1.</span> осуществляет внутренний контроль за соблюдением Оператором и его
                  работниками законодательства Российской Федерации о персональных данных, в
                  том числе требований к защите персональных данных.
                </li>
                <li><span class="marker">2.3.2.</span> контролирует прием и обработку обращений и запросов субъектов
                  персональных данных или их представителей.
                </li>
                <li><span class="marker">2.3.3.</span> принимает меры для обнаружения фактов несанкционированного доступа к
                  персональным данным и.
                </li>
                <li><span class="marker">2.3.4.</span> производит постоянный контроль за обеспечением уровня защищенности
                  персональных данных.
                </li>
                <li><span class="marker">2.3.5.</span> знакомит под роспись работников Оператора, которые имеют доступ к
                  персональным данным, с положениями законодательства РФ о персональных
                  данных, в том числе требованиями о защите персональных данных, а также с
                  локальными актами Оператора, определяющими порядок обработки Персональных
                  данных.
                </li>
                <li><span class="marker">2.3.6.</span> осуществляет внутренний контроль и (или) аудит соответствия обработки
                  персональных данных Федеральному закону от 27.07.2006 года № 152-ФЗ «О
                  персональных данных» и принятым в соответствии с ним нормативными правовыми
                  актами, требованиям к защите персональных данных, политике Оператора в
                  отношении обработки персональных данных, локальным актам Оператора.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li><span class="marker">3.</span> Порядок обеспечения прав субъекта персональных данных
          <ol>
            <li><span class="marker">3.1.</span> Субъекты персональных данных или их представители обладают правами,
              предусмотренными Федеральным законом от 27.07.2006 N 152-ФЗ «О
              персональных данных».
            </li>
            <li><span class="marker">3.2.</span> Оператор обеспечивает права субъектов персональных данных в порядке,
              установленном главами 3 и 4 Федерального закона от 27.07.2006 N 152-ФЗ «О
              персональных данных».
            </li>
            <li><span class="marker">3.3.</span> Полномочия представителя на представление интересов каждого субъекта
              персональных данных подтверждаются соответствующей доверенностью.
            </li>
            <li><span class="marker">3.4.</span> Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ
              «О персональных данных», предоставляются субъекту персональных данных в
              доступной форме без персональных данных, относящихся к другим субъектам
              персональных данных, за исключением случаев, если имеются законные основания
              для раскрытия таких персональных данных, в электронном виде. По требованию
              субъекта персональных данных они могут быть продублированы на бумаге.
              Доступная форма на бумаге должна быть заверена Оператором.
            </li>
            <li><span class="marker">3.5.</span> Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ
              «О персональных данных», предоставляются субъекту персональных данных или
              его представителю при личном обращении либо при получении запроса субъекта
              персональных данных или его представителя. Запрос должен содержать номер
              основного документа, удостоверяющего личность субъекта персональных данных
              или его представителя, сведения о дате выдачи указанного документа и выдавшем
              его органе, сведения, подтверждающие участие субъекта персональных данных в
              отношениях с Оператором (номер договора, дата заключения договора, условное
              словесное обозначение и (или) иные сведения), либо сведения, иным образом
              подтверждающие факт обработки персональных данных Оператором, подпись
              субъекта персональных данных или его представителя. При наличии технической
              возможности запрос может быть направлен в форме электронного документа и
              подписан электронной подписью в соответствии с законодательством Российской
              Федерации.
            </li>
            <li><span class="marker">3.6.</span> Право субъекта персональных данных на доступ к его персональным данным
              может быть ограничено в соответствии с федеральными законами.
            </li>
            <li><span class="marker">3.7.</span> Оператор обязан немедленно прекратить по требованию субъекта
              персональных данных обработку его персональных данных, указанную в ч. 1 ст. 15
              Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».
            </li>
            <li><span class="marker">3.8.</span> Оператор обязан предоставить безвозмездно субъекту персональных данных
              или его представителю возможность ознакомления с персональными данными,
              относящимися к этому субъекту персональных данных, по месту своего
              расположения в рабочее время.
              Перед началом использования сайта маркетплейс Etikaego просим Вас внимательно
              ознакомиться с настоящим документом. Использование сайта разрешается только
              при соблюдении условий данного соглашения. Принимая настоящее соглашение,
              вы берёте на себя обязательство соблюдать соглашение, приведенное ниже:
            </li>
          </ol>
        </li>
      </ol>
      <!--eslint-enable-->
    </article>
  </main>
</template>

<script>
export default {
  name: "PersonalData",
};
</script>

<style lang="stylus">
.main.article-page {
  display: grid;
  padding: 20px 32px 100px;
  background: var(--white);

  article {
    display grid

    ul
    ol {
      margin: revert
      padding-inline-start 50px

      li {
        position relative

        .marker {
          absolute left -50px top
          width 45px
          font-weight bold
          text-align right
        }
      }
    }
  }
}
</style>
